.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-transition {
  display: flex;
  flex: 1;
}

.slide-routes {
  height: 100%;
  overflow-y: auto;
}
